import React, { Component } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { AlertList, DisplayDetail, DisplayLocation, DisplayComments, BrightnessHistory, BrightnessConfig, CameraHistory, TemperatureHistory, DisplayRouter, DisplayISP, HeatMap } from '../../views';
import { MdWbSunny, MdMessage } from 'react-icons/md';
import { FaTemperatureLow, FaSignal, FaWifi } from 'react-icons/fa';
import _ from 'lodash';
import { utils } from '../../utils'

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class Aside extends Component {

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
      commentsCount: 0,
    };

    this.onCommentsChange = this.onCommentsChange.bind(this);
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  componentDidMount() {
    const user = utils.getUser()
    this.setState({
      activeTab: user.permissions.canViewExt(utils.Resources.displayAlerts) ? '1' : '3',
    })
  }

  onCommentsChange(commentsCount) {
    this.setState({commentsCount})
  }

  render() {

    // eslint-disable-next-line
    const { children, ...attributes } = this.props;
    const user = utils.getUser();
    const canViewExt = user.permissions.canViewExt(utils.Resources.displayAlerts) 
    const canViewComments = user.permissions.canViewExt(utils.Resources.displayComments) 
    const adminRole = (_.get(user,"payload.role") === "admin")

    return (
      <React.Fragment>
        <Nav tabs>
          {canViewExt &&
          <NavItem>
            <NavLink className={classNames({ active: this.state.activeTab === '1' })} title="Alerts"
                     onClick={() => {
                       this.toggle('1');
                     }}>
              <i className="icon-bell"></i>
            </NavLink>
          </NavItem>
          }
          {canViewExt &&
          <NavItem>
            <NavLink className={classNames({ active: this.state.activeTab === '2' })} title="Display details"
                     onClick={() => {
                       this.toggle('2');
                     }}>
              <i className="icon-screen-desktop"></i>
            </NavLink>
          </NavItem>
          }
          <NavItem>
            <NavLink className={classNames({ active: this.state.activeTab === '3' })} title="Camera snapshots"
                     onClick={() => {
                       this.toggle('3');
                     }}>
              <i className="icon-camera"></i>
            </NavLink>
          </NavItem>
          {canViewExt &&
          <NavItem>
            <NavLink className={classNames({ active: this.state.activeTab === '4' })} title="Brightness"
                     onClick={() => {
                       this.toggle('4');
                     }}>
              <MdWbSunny className="mb-1"/>
            </NavLink>
          </NavItem>
          }
          {adminRole &&
          <NavItem>
            <NavLink className={classNames({ active: this.state.activeTab === '5' })} title="Temperature"
                     onClick={() => {
                       this.toggle('5');
                     }}>
              <FaTemperatureLow className="mb-1"/>
            </NavLink>
          </NavItem>
          }
          <NavItem>
            <NavLink className={classNames({ active: this.state.activeTab === '6' })} title="Location"
                     onClick={() => {
                       this.toggle('6');
                     }}>
              <i className="icon-location-pin"></i>
            </NavLink>
          </NavItem>
          {adminRole &&
          <NavItem>
            <NavLink className={classNames({ active: this.state.activeTab === '7' })} title="Router"
                     onClick={() => {
                       this.toggle('7');
                     }}>
              <FaSignal className="mb-1"/>
            </NavLink>
          </NavItem>
          }
          {adminRole &&
          <NavItem>
            <NavLink className={classNames({ active: this.state.activeTab === '8' })} title="Internet Service Provider"
                     onClick={() => {
                       this.toggle('8');
                     }}>
              <FaWifi className="mb-1"/>
            </NavLink>
          </NavItem>
          }
          {canViewComments &&
          <NavItem>
            <NavLink className={classNames({ active: this.state.activeTab === '9' })} title={`Comments${this.state.commentsCount>0 ? ` (${this.state.commentsCount})` : ``}`}
                     onClick={() => {
                       this.toggle('9');
                     }}>
              <MdMessage className="mb-1"/>
              {(this.state.commentsCount>0) &&
                <small className="dot bg-success">&nbsp;</small>
              }
            </NavLink>
          </NavItem>
          }
        </Nav>

        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1" className="p-2 h-100">
            <AlertList />
          </TabPane>
          <TabPane tabId="2" className="p-2 h-100">
            <DisplayDetail></DisplayDetail>
          </TabPane>
          <TabPane tabId="3" className="p-2 h-100">
            <CameraHistory />
          </TabPane>
          <TabPane tabId="4" className="p-2 h-100">
            <div className="container h-100 m-0 p-0 pb-2">
              <div className="col h-100 m-0 p-0">
                <div className="row h-50 w-100 m-0 pb-2">
                  <BrightnessConfig></BrightnessConfig>
                </div>
                <div className="row h-50 w-100 m-0 p-0">
                  <BrightnessHistory></BrightnessHistory>
                </div>
              </div>
            </div>
          </TabPane>
          <TabPane tabId="5" className="p-2 h-100">
            <div className="container h-100 m-0 p-0 pb-2">
              <div className="col h-100 m-0 p-0">
                <div className="row h-70 w-100 m-0 pb-2">
                  <TemperatureHistory />
                </div>
                <div className="row h-70 w-100 m-0 p-0">
                  <HeatMap />
                </div>
              </div>
            </div>
            
          </TabPane>
          <TabPane tabId="6" className="p-2 h-100">
            <DisplayLocation></DisplayLocation>
          </TabPane>
          <TabPane tabId="7" className="p-2 h-100">
            {(this.state.activeTab==="7") && <DisplayRouter />}
          </TabPane>
          <TabPane tabId="8" className="p-2 h-100">
            {(this.state.activeTab==="8") && <DisplayISP />}
          </TabPane>
          <TabPane tabId="9" className="p-2 h-100">
            <DisplayComments isVisible={this.state.activeTab==="9"} onCommentsChange={this.onCommentsChange} ></DisplayComments>
          </TabPane>
        </TabContent>
      </React.Fragment>
    );
  }
}

Aside.propTypes = propTypes;
Aside.defaultProps = defaultProps;

export default Aside;
